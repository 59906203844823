import React from "react"
import {Link} from "gatsby";
import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import SEO from "../components/seo";

import FeaturePararaph from '../components/feature-paragraph'

import ListItem from '../components/list-item';


const Terms = () => (
  <Layout>
    <SEO 
    	title="Terms"
    	description="Terms and Conditions of using TimeKeeper "
    	 />
    <Navbar />
    <div className="relative py-16 bg-white overflow-hidden">
		<div className="flex flex-col items-center px-4 sm:px-6 lg:px-8">
		    <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
		      TimeKeeper Terms & Conditions
		    </h1>

		</div>
		<div className="mt-12 w-full prose lg:prose-xl text-gray-600 mx-auto">
			<p><i>Last Updated: 6th June 2023</i></p>
			<p><strong>1. Interpretation</strong></p>
			<p>The following definitions and rules of interpretation apply in these Conditions.</p>
			<p><strong>1.1. Definitions</strong></p>
			<p><strong>API:</strong> Application Program Interfaces, that being a set of protocols, routines, and tools that enable different software applications to communicate and interact with each other, by acting as an intermediary between applications, allowing for the sharing of data and functionality.</p>
			<p><strong>Applicable Laws:</strong> means all applicable laws, regulations, regulatory requirements and codes of practice of any relevant jurisdiction, as amended and in force from time to time.</p>
			<p><strong>Business Day:</strong> a day other than a Saturday, Sunday or public holiday in Northern Ireland, when banks in Belfast are open for business.</p>
			<p><strong>Commencement Date:</strong> has the meaning given in clause 2.3.</p>
			<p><strong>Conditions:</strong> these terms and conditions as amended from time to time in accordance with clause 18.5.</p>
			<p><strong>Contract:</strong> the contract between the Supplier and the Customer for the supply of Services in accordance with these Conditions.</p>
			<p><strong>Control:</strong> has the meaning given in section 1124 of the Corporation Tax Act 2010, and the expression change of control shall be construed accordingly.</p>
			<p><strong>Customer:</strong> the person or firm who purchases the Services from the Supplier.</p>
			<p><strong>Customer Data:</strong> the data inputted by the Customer, Users, or the Supplier on the Customer's behalf for the purpose of using the Services or facilitating the Customer's use of the Services.</p>
			<p><strong>Customer Default:</strong> has the meaning set out in clause 7.2.</p>
			<p><strong>Employee Directory:</strong> the list of Users maintained by the Supplier.</p>
			<p><strong>Intellectual Property Rights:</strong> patents, rights to inventions, copyright and rights, moral rights, trademarks and service marks, business names and domain names, rights in get-up, goodwill and the right to sue for passing off or unfair competition, rights in designs, rights in computer software, database rights, rights to use, and protect the confidentiality of, confidential information (including know-how and trade secrets), and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world.</p>
			<p><strong>Paid Subscription Period:</strong> the period during the Subscription Term, and following the conclusion of the Services Trial Period, where the Customer has provided the Supplier with the information required pursuant to clause 8.2 and where the Customer continues to access the Services on paid basis in accordance with clause 8.</p>
			<p><strong>Renewal Term:</strong> the period described in clause 5.11.</p>
			<p><strong>Services:</strong> the subscription services provided by the Supplier to the Customer as regards the provision of time and attendance Software by the Supplier for the benefit of the Customer, to include, but not limited to the Supplier’s website (including its subdomains and any other website that the Supplier offers the Services through), applications for mobile, tablet and other smart device systems, API or any applications, sample and content files, source code, scripts, instruction sets or software included as part of the Services, to include any related documentation.</p>
			<p><strong>Services Trial:</strong> the Services provided to the Customer immediately after the submission of the Subscription Order Form, for a non-renewable period of 14 days during which time the Subscription Fees are not applied by the Supplier.</p>
			<p><strong>Services Trial Period:</strong> the 14 day period of the Services Trial.</p>
			<p><strong>Software:</strong> the online software applications provided by the Supplier as part of the Services.</p>
			<p><strong>Subscription:</strong> the Customer's order for Services as set out in the Subscription Order Form, submitted by the Customer in advance of the commencement of the Services Trial, together with any additional services that may from time to time be added the Subscription during the Subscription Term.</p>
			<p><strong>Subscription Fees:</strong> the charges payable by the Customer for the supply of the Services in accordance with clause 8, including but not limited to all fees, taxes and costs (including, delivery costs, if applicable).</p>
			<p><strong>Subscription Order Form:</strong> the online order form submitted by the Customer when ordering the Subscription in accordance with the provisions of clause 2.</p>
			<p><strong>Subscription Term:</strong> the initial term of this agreement as set out in clause 5.10.</p>
			<p><strong>Supplier:</strong>  Artificialdev Limited (trading as TimeKeeper) registered in Northern Ireland with company number NI647093, having its registered office at LoftSpace, 19 Arthur Street, Belfast, Antrim, Northern Ireland, BT1 4GA.</p>
			<p><strong>Term:</strong> either the Subscription Term or the Renewal Term.</p>
			<p><strong>User:</strong> the employees, servants, agents or contractors of the Customer who are authorised by the Customer, and approved by the Supplier, to access and use the Services in accordance with the Contract, as further described in clause 3.</p>
			<p><strong>User Subscriptions:</strong> the user subscriptions purchased by the Customer pursuant to clause 8 which entitle Users to access and use the Services in accordance with the Contract.</p>
			<p><strong>Virus:</strong> anything or device (including any software, code, file or programme) which may: prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any programme or data, including the reliability of any programme or data (whether by re-arranging, altering or erasing the programme or data in whole or part or otherwise); or adversely affect the user experience, including worms, trojan horses, viruses and other similar things or devices, and the term Viruses shall be interpreted accordingly.</p>
			<p><strong>Vulnerability:</strong> a weakness in the computational logic (for example, code) found in software and hardware components that when exploited, results in a negative impact to the confidentiality, integrity, or availability, and the term Vulnerabilities shall be interpreted accordingly.</p>

			<p>1.2 Interpretation:</p>
		    <ul>
		        <li>(a) A reference to legislation or a legislative provision:
		            <ul>
		                <li>(i) is a reference to it as amended, extended or re-enacted from time to time; and</li>
		                <li>(ii) shall include all subordinate legislation made from time to time under that legislation or legislative provision.</li>
		            </ul>
		        </li>
		        <li>(b) Any words following the terms <strong>including, include, in particular, for example</strong> or any similar expression:  shall be construed as illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding those terms.</li>
		        <li>(c) A reference to <strong>writing</strong> or <strong>written</strong> includes email.</li>
		    </ul>

		    <p><strong>2. Basis of contract</strong></p>
		    <ul>
		        <li>2.1 The Subscription Order Form: constitutes an offer by the Customer to purchase Services in accordance with these Conditions.</li>
		        <li>2.2 To enter into a Subscription: the Customer must register an account with the Supplier by providing a name, email address and password, as well as confirming acceptance of these Conditions.</li>
		        <li>2.3 The offer: shall only be deemed to be accepted when the Supplier provides the Customer (and related Users) access to the Services Trial, at which point and on which date the Contract shall come into existence and creates an obligation for the Customer to adhere to the Conditions (<strong>Commencement Date</strong>), save for the provisions of clause 8, which shall not apply during the Services Trial Period.</li>
		        <li>2.4 All notifications from the Supplier: that relate to the Subscription shall be sent to the email address provided by the Customer at the time that the Subscription is made.</li>
		        <li>2.5 Any samples, drawings, descriptive matter or advertising: issued by the Supplier, and any descriptions or illustrations contained on the Supplier's website, associated sub-domains, application or other marketing media (including, as the case may be, graphic material, images, colours or sounds), are issued or published for the sole purpose of giving an approximate idea of the Services described in them. While products and/or the Services are presented with the greatest accuracy technically possible, representation in the aforesaid manner is for reference only and implies no warranty as to the characteristics of the Services and they shall not form part of the Contract or have any contractual force.</li>
		        <li>2.6 These Conditions: apply to the Contract to the exclusion of any other terms that the Customer seeks to impose or incorporate, or which are implied by law, trade custom, practice or course of dealing.</li>
		        <li>2.7 Any quotation: given by the Supplier as regards bespoke Services shall not constitute an offer and is only valid for a period of 20 Business Days from its date of issue.</li>
		    </ul>
		    <p><strong>3. User subscriptions</strong></p>
		    <ul>
		        <li>3.1 Subject to the Customer purchasing the User Subscriptions in accordance with clause 4 and clause 8, the restrictions set out in this clause 3 and the Conditions generally, the Supplier hereby grants to the Customer a non-exclusive, non-transferable right and licence, without the right to grant sublicences, to permit the Users to use the Services during the Term solely for the Customer's internal business operations.</li>
		        <li>3.2 In relation to the Users, the Customer undertakes that:
		            <ul>
		                <li>(a) the maximum number of Users that it authorises to access and use the Services shall not exceed the number of User Subscriptions it has purchased from time to time;</li>
		                <li>(b) it will not allow or suffer any User Subscription to be used by more than one individual User unless it has been reassigned in its entirety to another individual User, in which case the prior User shall no longer have any right to access or use the Services;</li>
		                <li>(c) each User shall keep a secure password for their use of the Services and this password shall be kept confidential;</li>
		            </ul>
		        </li>
		        <li>3.3 The Customer shall not access, store, distribute or transmit any Viruses, or any material during the course of its use of the Services, or that of the Users, that:
		            <ul>
		                <li>(a) is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive;</li>
		                <li>(b) facilitates illegal activity;</li>
		                <li>(c) depicts sexually explicit images;</li>
		                <li>(d) promotes unlawful violence;</li>
		                <li>(e) is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or</li>
		                <li>(f) is otherwise illegal or causes damage or injury to any person or property;</li>
		            </ul>
		        </li>
		        <p>and the Supplier reserves the right, without liability or prejudice to its other rights to the Customer, to disable the Customer's (and any User’s) access to any material that breaches the provisions of this clause.</p>
		    </ul>
	        <li>3.4 The Customer shall not:
	            <ul>
	                <li>(a) except as may be allowed by any Applicable Laws which is incapable of exclusion by agreement between the parties and except to the extent expressly permitted under this agreement:
	                    <ul>
	                        <li>(i) attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Software (as applicable) in any form or media or by any means; or</li>
	                        <li>(ii) attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Software; or</li>
	                    </ul>
	                </li>
	                <li>(b) access all or any part of the Services in order to build a product or service which competes with the Services; or</li>
	                <li>(c) use the Services to provide services to third parties; or</li>
	                <li>(d) subject to clause 18.2, license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services available to any third party except the Users, or</li>
	                <li>(e) attempt to obtain, or assist third parties in obtaining, access to the Services, other than as provided under this clause 3; or</li>
	                <li>(f) introduce or permit the introduction of, any Virus or Vulnerability into the Supplier's network and information systems.</li>
	            </ul>
	        </li>
	        <li>3.5 The Customer shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the Services and, in the event of any such unauthorised access or use, promptly notify the Supplier.</li>
	        <li>3.6 The rights provided under this clause 3 are granted to the Customer (and its Users) only, and shall not be considered granted to any subsidiary or holding company of the Customer.</li>
		    
		    <p><strong>4. Additional User subscriptions</strong></p>
	        <li>4.1 Subject to clause 4.2 and clause 4.3, the Customer may, from time to time during the Term, purchase additional User Subscriptions in excess of the number confirmed in the Subscription order and the Supplier shall grant access to the Services to such additional Users in accordance with the provisions of this agreement.</li>
	        <li>4.2 If the Customer wishes to purchase additional User Subscriptions, the Customer shall first add the additional User to the Employee Directory. The Supplier shall thereafter send an email to the additional User providing him/her with a PIN code and requesting that he/she set up a password and provide confirmation that he/she has read these Conditions. The Supplier shall at all times be at liberty to reject such a request for additional User Subscriptions at any time. Where the Supplier does not object to the request, and where the additional User has fulfilled the requirements under this clause 4.2, the Supplier shall activate the additional User Subscription as soon as is practicable, using best endeavours to do so.</li>
	        <li>4.3 If the Supplier approves the Customer's request to purchase additional User Subscriptions, the Customer shall pay to the Supplier the relevant Subscription Fees for such additional User Subscriptions as provided for in clause 8. If such additional User Subscriptions are purchased by the Customer at any time after the commencement of the Subscription Term or any Renewal Term (as applicable), such fees shall be pro-rated from the date of activation by the Supplier for the remainder of the Subscription Term or then current Renewal Term (as applicable).</li>
		    
		    <p><strong>5. Supply of Services</strong></p>
	        <li>5.1 The Supplier shall, during the Term, provide the Services to the Customer on and subject to the terms of this agreement.</li>
	        <li>5.2 The Supplier shall use commercially reasonable endeavours to make the Services available 24 hours a day, seven days a week, except for periods when maintenance must be carried out.</li>
	        <li>5.3 Prices, descriptions or availability of the Services shall at all times be subject to change at the discretion of the Supplier.</li>
	        <li>5.4 The Supplier shall use all reasonable endeavours to meet any performance dates specified in the Subscription, but any such dates shall be estimates only and time shall not be of the essence for performance of the Services.</li>
	        <li>5.5 The Supplier warrants to the Customer that the Services will be provided using reasonable care and skill, and that the Services will be made available on as soon as is reasonably practicable with the Supplier using its best endeavours in this regard.</li>
	        <li>5.6 The Services are only intended for use by businesses and should not be considered to be appropriate for consumers.</li>
	        <li>5.7 The Services are age restricted, with access and use of the Services being prohibited for persons under the legal age of adulthood under Applicable Laws.</li>
	        <li>5.8 At the end of the Services Trial Period access to the Services shall immediately end. To continue to access the Services, the Customer must pay the Subscription Fees in accordance with clause 8.</li>
	        <li>5.9 The Paid Subscription commences on the date that the first instalment of the Subscription Fees is received by the Supplier.</li>
	        <li>5.10 The Subscription Term shall include the Services Trial Period and shall last for a further period of either one-month or 12-months, depending on the selection of the Customer (“Customer Selection”), from the end of the Services Trial Period. When providing payment details the Customer shall confirm if it wishes the Subscription Term to last further period of either one-month or 12-months from the end of the Services Trial Period, and the Customer Selection, in addition to the Services Trail Period, shall together be the Subscription Term of the Contract.</li>
	        <li>5.11 At the end of the Subscription Term, the Contract shall automatically renew for successive one-month or 12-month periods (depending on the Customer Selection referred to in the above clause 5.10 and each being a “Renewal Term”) unless the Customer provides written notice of termination to the Supplier or selects the cancellation option through the Supplier’s web portal, prior to the expiration of the Subscription Term or the Renewal Term.</li>
	        <li>5.12 In the absence of a written notice of termination as specified in clause 5.11 above, the Contract shall continue to renew for successive Renewal Terms. Where the Subscription Term is for a period of 12-months after the end of the Services Trial Period, and in circumstances where the Renewal Term has commenced, the Customer shall only be entitled to a refund of the Subscription Fee following termination of the Contract if Notice of termination is provided to the Supplier within a period of one month of the commencement of the Renewal Term.</li>
	        <li>5.13 The Conditions shall apply to each Renewal Term, unless otherwise agreed upon by the parties in writing. The Subscription Fees for the Services shall be subject to review and adjustment by the Supplier upon each Renewal Term and in accordance with these Conditions.</li>
	        <li>5.14 The undertaking at clause 5.5 shall not apply to the extent of any non-conformance which is caused by use of the Services contrary to the Supplier's instructions, or modification or alteration of the Services by any party other than the Supplier or the Supplier's duly authorised contractors or agents.</li>
	        <li>5.15 The Supplier:
	            <ul>
	                <li>(a) does not warrant that:
	                    <ul>
	                        <li>(i) the Customer's use of the Services will be uninterrupted or error-free;</li>
	                        <li>(ii) that the Services or the information obtained by the Customer through the Services will meet the Customer's requirements; or</li>
	                        <li>(iii) the Software or the Services will be free from Vulnerabilities or Viruses.</li>
	                    </ul>
	                </li>
	                <li>(b) is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and the Customer acknowledges that the Services may be subject to limitations, delays and other problems inherent in the use of such communications facilities.</li>
	            </ul>
	        </li>
	        <li>5.16 The Supplier warrants that it has and will maintain all necessary licences, consents, and permissions necessary for the performance of its obligations under this agreement.</li>
	        <li>5.17 The Supplier shall follow its archiving procedures and other relevant data protection policies/procedures for Customer Data, as may be amended by the Supplier in its sole discretion from time to time. In the event of any loss or damage to Customer Data, the Customer's sole and exclusive remedy against the Supplier shall be for the Supplier to use reasonable commercial endeavours to restore the lost or damaged Customer Data from the latest back-up of such Customer Data maintained by the Supplier. The Supplier shall not be responsible for any loss, destruction, alteration or disclosure of Customer Data caused by any third party.</li>
		    
		    <p><strong>6. Services Trial</strong></p>
		    <ul>
		        <li>6.1 The Supplier shall provide the Customer with a free trial period ("Services Trial") for the use of the Services specified in the Contract.</li>
		        <li>6.2 The Free Trial shall commence on submission of the Subscription Order Form and shall continue for a period of 14 days from that date (“Services Trial Period”).</li>
		        <li>6.3 The Customer acknowledges and agrees that this Contract, including all rights and obligations, shall commence upon the Commencement Date, which shall be the same as the start date of the Services Trial.</li>
		        <li>6.4 During the Services Trial Period, the Customer shall have access to and use of the Services without payment of any fees.</li>
		        <li>6.5 At the end of the Services Trial Period, the Customer shall have the option to either:
		            <ul>
		                <li>(a) subscribe to the Services on a paid basis, in which these Conditions shall continue to apply; or</li>
		                <li>(b) Terminate the use of the Services without any further obligation or liability.</li>
		            </ul>
		        </li>
		        <li>The Supplier may, at its discretion, limit or restrict certain features or functionalities of the Services during the Services Trial Period, and not all of the features or functions of the Services shall be available to Users during the Services Trial Period.</li>
		        <li>6.7 The Supplier reserves the right to terminate the Services Trial at any time without prior notice if it determines, in its sole discretion, that the Customer is in violation of the terms of this Contract or misusing the Services.</li>
		        <li>6.8 During the Services Trial Period, the Customer acknowledges and agrees that the Services are provided on an "as is" basis, and the Supplier makes no warranties or representations regarding the performance, functionality, or suitability of the Services.</li>
		        <li>6.9 Any data or information entered or generated by the Customer during the Services Trial Period may be retained by the Supplier and made available to the Customer if the Customer chooses to subscribe to the Services on a paid basis. However, the Supplier shall have no obligation to retain or provide access to such data or information if the Customer decides not to subscribe to the Services.</li>
		        <li>6.10 This Services Trial Period clause does not create any obligation on the Customer to subscribe to the Services on a paid basis, and the Supplier shall have no liability to the Customer for any consequences arising from the Customer's decision not to subscribe to the Services at the end of the Services Trial Period.</li>
		    </ul>
		    <p>7. Customer's obligations</p>
		    <ul>
		        <li>
		            7.1 The Customer shall:
		            <ul>
		                <li>(a) ensure that the terms of the Subscription and any information it provides are complete and accurate;</li>
		                <li>(b) co-operate with the Supplier in all matters relating to the Services;</li>
		                <li>(c) provide the Supplier with such information and materials as the Supplier may reasonably require in order to supply the Services, and ensure that such information is complete and accurate in all material respects;</li>
		                <li>(d) Register with the Supplier by creating a user account, providing all required information to complete the registration in an accurate and honest manner.</li>
		                <li>(e) Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by the Supplier.</li>
		                <li>(f) By registering, Users agree to be fully responsible for all activities that occur under their username and password.</li>
		                <li>(g) Users are required to immediately and unambiguously inform the Supplier via the contact details provided in clause 8.9, if they suspect that their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.</li>
		                <li>(h) Unless otherwise specified, each User must register only one account.</li>
		                <li>(i) Unless explicitly permitted, a User account may not be shared with other persons.</li>
		                <li>(j) Accounts registered by bots or any other automated methods are not permitted.</li>
		                <li>(k) The Customer is responsible for the manner in which the Users access and use the Services. It is the responsibility of the Customer to ensure each User is made aware of the terms of the Contract and more specially the obligations it places on both the Customer and Users. The Customer shall also ensure that its Employee Handbook is updated to reflect the fact that the Supplier is being engaged to supply the Services.</li>
		            </ul>
		        </li>
		        <li>
		            7.2 If the Supplier's performance of any of its obligations under the Contract is prevented or delayed by any act or omission by the Customer or failure by the Customer to perform any relevant obligation (<strong>Customer Default</strong>):
		            <ul>
		                <li>(a) The Supplier shall delay, suspend or prevent the Customer’s use of the Services.</li>
		                <li>(b) without limiting or affecting any other right or remedy available to it, the Supplier shall have the right to suspend performance of the Services until the Customer remedies the Customer Default, and to rely on the Customer Default to relieve it from the performance of any of its obligations in each case to the extent the Customer Default prevents or delays the Supplier's performance of any of its obligations;</li>
		                <li>(c) the Supplier shall not be liable for any costs or losses sustained or incurred by the Customer arising directly or indirectly from the Supplier's failure or delay to perform any of its obligations as set out in this clause 7.2; and</li>
		            </ul>
		        </li>
		        <li>
		            7.3 The Services may only be used within the scope for which they are provided and under the terms of the Contract and in accordance with Applicable Laws. The Customer is solely responsible for making sure that its use of the Services, and that of the Users, violates no third-party rights and is in accordance at all times with Applicable Laws.
		        </li>
		        <li>
		            7.4 The Supplier reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to the Software and the Services, terminating contracts, reporting any misconduct performed through the Software or the Services to the relevant authorities (including but not limited to judicial or administrative authorities) should  Users engage or are suspected to engage in any of the following activities:
		            <ul>
		                <li>(a) violate laws, regulations and/or these Conditions;</li>
		                <li>(b) infringe any third-party rights;</li>
		                <li>(c) impair the Supplier’s legitimate interests; or</li>
		                <li>(d) offend the Supplier or any third party.</li>
		            </ul>
		        </li>
		        <li>
		            7.5 Users may access their data relating to the Services through the API. Any use of the API, including use of the API through a third-party product/service that accesses the Services, shall be subject to these Conditions. The Supplier bears no responsibility and shall not be held liable for any damages or losses resulting from the User’s use of the API or their use of any third-party products/services that access data through the API. The obligations placed on the User by clause 3.3 shall also apply to the User’s access to the API.
		        </li>
		        <li>
		            7.6 It is the responsibility of the User to ensure the API Key that is provided by the Supplier is kept in a safe place and that all reasonable measures are taken to ensure the API Key is not compromised. The User is obligated to inform the Supplier if the API Key is lost or in any way compromised or exposed to the potential of being compromised.
		        </li>
		    </ul>
		    <p><strong>8. Subscription Fees and payment</strong></p>
		    <ul>
		        <li>
		            8.1 The Customer shall be informed of the Subscription Fees in advance of the Customer’s submission of the Subscription Order Form and shall pay the Subscription Fees to the Supplier for the User Subscriptions in accordance with this clause 8.
		        </li>
		        <li>
		            8.2 At the end of the Services Trial Period, and where the Customer wishes to continue with the Subscription (the Paid Subscription Period commencement), the Customer shall provide to the Supplier valid, up-to-date and complete credit/debit card details or other forms of purchase order information acceptable to the Supplier, together with any other relevant valid, up-to-date and complete contact and billing details as the Supplier may require from time to time.
		        </li>
		        <li>
		            8.3 Where the Customer provides:
		            <ul>
		                <li>(a) its credit/debit card details to the Supplier, the Customer hereby authorises the Supplier to bill such credit/debit card:
		                    <ul>
		                        <li>
		                            (i) at the commencement of the Paid Subscription Period, when the Customer has provided to the Supplier the information referred to in clause 8.2 (for the Subscription Term); and
		                        </li>
		                        <li>
		                            (ii) subject to clauses 5.11 and 16, on each anniversary of the commencement of the Paid Subscription referred to in the above clause 8.3(a)(i) (for the Renewal Term).
		                        </li>
		                    </ul>
		                </li>
		                <li>
		                    (b) its approved purchase order information to the Supplier and/or where bespoke services are being provided, the Supplier shall invoice the Customer:
		                    <ul>
		                        <li>
		                            (i) at the commencement of the Paid Subscription Period (for the Subscription Term); and
		                        </li>
		                        <li>
		                            (ii) subject to clause 5.11 and 16, at least 30 days prior to each anniversary of the commencement of the Paid Subscription Period (for the Renewal Term);
		                        </li>
		                        and the Customer shall pay each invoice within 30 days after the date of such invoice in full and in cleared funds to a bank account nominated in writing by the Supplier and time for payment shall be of the essence of the Contract.
		                    </ul>
		                </li>
		            </ul>
		        </li>
		        <li>
		            8.4 If the Supplier has not received payment within 30 days after the due date, and without prejudice to any other rights and remedies of the Supplier:
		            <ul>
		                <li>
		                    (a) the Supplier may, without liability to the Customer, disable the Customer's password, account and access to all or part of the Services and the Supplier shall be under no obligation to provide any or all of the Services while the invoice(s) concerned remain unpaid; and
		                </li>
		                <li>
		                    (b) interest shall accrue on a daily basis on such due amounts at an annual rate equal to 8% over the then current base lending rate of the Bank of England from time to time, commencing on the due date and continuing until fully paid, whether before or after judgment.
		                </li>
		            </ul>
		        </li>
		        <li>
		            8.5 All amounts and fees stated or referred to in this agreement:
		            <ul>
		                <li>(a) shall be payable in the currency determined by the Supplier from to time ;</li>
		                <li>(b) are, subject to clause 12, non-cancellable and non-refundable;</li>
		                <li>(c) are exclusive of value added tax (and any equivalent taxes for non-UK and EU jurisdictions), which shall be added to the Supplier's invoice(s) at the appropriate rate.</li>
		            </ul>
		        </li>
		        <li>
		            8.6 The Subscription Fees shall increase pro rata to the active days of use of the Services, where additional User Subscriptions are added under clause 4.
		        </li>
		        <li>
		            8.7 Notwithstanding the rights afforded to the Supplier pursuant to clause 5.3, the Supplier shall be entitled to increase the Subscription Fees and/or the fees payable in respect of the additional User Subscriptions purchased pursuant to clause 4, at the start of each Renewal Term by providing written notice to the Customer.
		        </li>
		        <li>
		            8.8 All payments are independently processed through third-party services. Therefore, the Supplier does not collect any payment information – such as credit card details – but only receives a notification once the payment has been successfully completed.  If a payment through the available methods fails or is refused by the payment service provider, the Supplier shall be under no obligation to fulfil the purchase order. Any possible costs or fees resulting from the failed or refused payment shall be borne by the Customer.
		        </li>
		        <li>
		            8.9 Some payment methods may only be available subject to additional conditions or fees. Customer’s may obtain further information as regards any such additional conditions or fees by contacting: support@timekeeper.co.uk.
		        </li>
		        <li>
		            8.10 All amounts due under the Contract shall be paid in full without any set-off, counterclaim, deduction or withholding (other than any deduction or withholding of tax as required by law).
		        </li>
		        <li>
		            8.11 The Supplier may in its absolute discretion offer discounts or commission incentives (Benefit), to Customers as regards Subscription Fees from time to time. The decision to offer Benefit, the amount of the discount and/or commission, and the duration of the Benefit offered shall be determined solely by the Supplier. Any Benefit offered by the Supplier shall be subject to the terms and conditions specified by the Supplier in writing or through its official communication channels. The Customer acknowledges and agrees that the availability of Benefit is not guaranteed, and that the Supplier may modify or withdraw any Benefit it offers at any time without prior notice. The Customer shall have no automatic or continuing entitlement to Benefit.  Any Benefit provided by the Supplier shall not create any obligation or expectation for future discounts, commissions or modifications to the pricing structure.
		        </li>
		    </ul>
		    <p><strong>9. Intellectual property rights</strong></p>
		    <ul>
		        <li>
		            9.1 The Customer acknowledges and agrees that the Supplier and/or its licensors own all Intellectual Property Rights in the Services. Except as expressly stated herein, this agreement does not grant the Customer any rights to, under or in, any patents, copyright, database right, trade secrets, trade names, trademarks (whether registered or unregistered), or any other rights or licences in respect of the Services.
		        </li>
		        <li>
		            9.2 The Customer shall not use the Services, and any associated content, in any way that is not necessary or implicit in the proper use of the Services. In particular, but without limitation, the Customer and/or Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on the Software and/or the Services, nor allow any third party to do so through the User or their device, even without the User's knowledge.
		        </li>
		        <li>
		            9.3 The Supplier confirms that it has all the rights in relation to the Services that are necessary to grant all the rights it purports to grant under, and in accordance with, the terms of this agreement.
		        </li>
		        <li>
		            9.4 Any applicable statutory limitation or exception to copyright shall stay unaffected.
		        </li>
		    </ul>
		    <p><strong>10. Data protection</strong></p>
		    <ul>
		        <li>
		            10.1 Both parties agree to comply with all applicable data protection laws, including but not limited to the General Data Protection Regulation ("GDPR"), in connection with the performance of their obligations under this agreement.
		        </li>
		        <li>
		            10.2 The Supplier shall process the personal data of the Customer's employees ("Data Subjects") in accordance with the Customer's instructions and solely for the purposes of providing the Services to the Customer. The Supplier shall not process the personal data for any other purpose without the prior written consent of the Customer.
		        </li>
		        <li>
		            10.3 The Supplier’s privacy notice is available on its website, which sets out the Supplier's obligations under data protection laws in relation to the processing of personal data. The Customer acknowledges that it has read and understood the privacy notice.
		        </li>
		        <li>
		            10.4 The Supplier shall ensure that any transfers of personal data outside of the European Economic Area ("EEA") are made in accordance with the GDPR and that appropriate safeguards are in place, including but not limited to the use of standard contractual clauses.
		        </li>
		        <li>
		            10.5 The Supplier shall implement appropriate technical and organisational measures to protect the personal data from unauthorised or unlawful processing, accidental loss or destruction, damage, alteration or disclosure.
		        </li>
		        <li>
		            10.6 The Supplier shall promptly notify the Customer in writing if it receives a request from a Data Subject to exercise their rights under the GDPR and shall assist the Customer in fulfilling its obligations to respond to such requests.
		        </li>
		        <li>
		            10.7 The Supplier shall notify the Customer without undue delay upon becoming aware of any personal data breach affecting the Data Subjects and shall cooperate with the Customer in investigating and remedying the breach.
		        </li>
		        <li>
		            10.8 The Supplier shall, in certain circumstances, be required to rely upon the services offered by third-party subprocessor. The Supplier shall ensure that any subprocessor engaged by the Supplier is subject to the same data protection obligations as set out in this agreement, and the Customer agrees to the use of third-party subprocessors by the Supplier in such circumstances.
		        </li>
		        <li>
		            10.9 The Supplier shall only retain personal data for as long as necessary to provide the Services to the Customer.
		        </li>
		        <li>
		            10.10 The Customer confirms that it is the Data Controller of the personal data processed by the Supplier under this agreement and that it has a legal basis under the GDPR for the processing of such personal data.
		        </li>
		        <li>
		            10.11 The Supplier shall not be liable for any breaches of the GDPR or other data protection laws to the extent that such breaches are caused by the Customer's failure to comply with its obligations as Data Controller, including but not limited to its failure to provide adequate instructions for the processing of personal data or to obtain the necessary consents, and the Supplier's liability is limited to the fees paid by the Customer under the Contract.
		        </li>
		        <li>
		            10.12 The liability of the Supplier for any breach of the Contract or otherwise arising out of or in connection with the processing of personal data under the Contract shall be limited to the fees paid by the Customer to the Supplier under the Contract.
		        </li>
		        <li>
		            10.13 This Data Processor clause shall survive termination of this Agreement.
        		</li>
		    </ul>
		    <p><strong>11. Third party providers</strong></p>
		    <ul>
		        <li>
		            11.1 The Customer acknowledges that the Services may enable or assist it to access the website content of, correspond with, and purchase products and services from, third parties via third-party websites and that it does so solely at its own risk. The Supplier makes no representation, warranty or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third-party website, or any transactions completed, and any contract entered into by the Customer, with any such third party. Any contract entered into and any transaction completed via any third-party website is between the Customer and the relevant third party, and not the Supplier. The Supplier recommends that the Customer refers to the third party's website terms and conditions and privacy policy prior to using the relevant third-party website. The Supplier does not endorse or approve any third-party website nor the content of any of the third-party website made available via the Services.
		        </li>
		    </ul>
		    <p><strong>12. Limitation of liability</strong></p>
		    <ul>
		        <li>12.1 Except as expressly and specifically provided in this agreement:
		            <ul>
		                <li>(a) the Customer assumes sole responsibility for results obtained from the use of the Services by the Customer, and for conclusions drawn from such use. The Supplier shall have no liability for any damage caused by errors or omissions in any information, instructions or scripts provided to the Supplier by the Customer in connection with the Services, or any actions taken by the Supplier at the Customer's direction;</li>
		                <li>(b) all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by Applicable Laws, excluded from this agreement; and</li>
		                <li>(c) the Services are provided to the Customer on an "as is" basis.</li>
		            </ul>
		        </li>
		        <li>12.2 Nothing in this agreement excludes the liability of the Supplier:
		            <ul>
		                <li>(a) for death or personal injury caused by the Supplier's negligence; or</li>
		                <li>(b) for fraud or fraudulent misrepresentation.</li>
		            </ul>
		        </li>
		        <li>12.3 Subject to clause 12.1 and clause 12.2:
		            <ul>
		                <li>(a) the Supplier shall not be liable whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, depletion of goodwill and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however arising under this agreement; and</li>
		                <li>(b) the Supplier's total aggregate liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of this agreement shall be limited to the total Subscription Fees paid for the User Subscriptions during the Subscription Term or during 12 months immediately preceding the date on which the claim arose, whichever is shorter.</li>
		            </ul>
		        </li>
		        <li>12.4 Nothing in this agreement excludes the liability of the Customer for any breach, infringement or misappropriation of the Supplier’s intellectual property rights.</li>
		        <li>12.5 The Supplier shall bear no liability for claims brought by the Customer in circumstances where the Customer has either incorrectly or inappropriately made use of the Services, or where the Customer has made use of the Services in a manner that is in breach of the terms of this agreement.</li>
		    </ul>
		    <p><strong>13. Indemnity</strong></p>
		    <ul>
		        <li>13.1 The Customer shall defend, indemnify and hold harmless the Supplier against claims, actions, proceedings, losses, damages, expenses and costs (including without limitation court costs and reasonable legal fees) arising out of or in connection with the Customer's use of the Services provided that:
		            <ul>
		                <li>(a) the Customer is given reasonable notice of any such claim;</li>
		                <li>(b) the Supplier provides reasonable co-operation to the Customer in the defence and settlement of such claim, at the Customer's expense; and</li>
		                <li>(c) the Customer is given sole authority to defend or settle the claim.</li>
		            </ul>
		        </li>
		    </ul>
		    <p><strong>14. Access to external resources</strong></p>
		    <ul>
		        <li>14.1 Users may have access to external resources provided by third parties. Users acknowledge and accept that the Supplier has no control over such resources and is therefore not responsible for their content and availability.</li>
		        <li>14.2 Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, Applicable Laws, and as such the Supplier shall have no responsibility for third party resources, content or access.</li>
		    </ul>
		    <p><strong>15. Content provided by Users</strong></p>
		    <ul>
		        <li>15.1 The Supplier shall permit Users to upload, share or provide their own content and/or data to the Software. This permission is subject to the Users having legal authority to upload such content, and that in doing so the Users are not, in any way infringing the rights of any third party, or acting in breach of the Applicable Laws, or contrary to the provisions of clause 3.3, or the Conditions of the Contract generally.</li>
		        <li>15.2 The Customer and Users acknowledge and accept that by providing their own content on the Services they grant the Supplier a non-exclusive, fully paid-up and royalty-free license to process such content solely for the operation and maintenance of the Software and the Services in accordance with the Conditions.</li>
		        <li>15.3 To the extent permitted by Applicable Laws, the Customer and Users waive any moral rights in connection with content they provide to the Software. Users acknowledge, accept and confirm that all content they add to the Software is provided subject to the same Conditions that apply to this Contract.</li>
		        <li>15.4 Users are solely responsible for any content they upload, post, share, or provide through the Services and on the Software, and the Customer and the Users shall be jointly and severally liable for all losses, liabilities and claims that might arise from the uploading, posting, sharing, or providing of such content on the Services. The Customer and Users, acknowledge and accept that the Supplier does not review or moderate such content, and any such overview and regulation of this content is the sole responsibility of the Customer.</li>
		        <li>15.5 The Supplier reserves the right to remove, delete, block or rectify such content at its own discretion and to, without prior notice, deny the uploading User access to the Services:
		            <ul>
		                <li>(a) if any complaint based on such content is received;</li>
		                <li>(b) if a notice of infringement of intellectual property rights is received;</li>
		                <li>(c) upon order of a public authority;</li>
		                <li>(d) where the Supplier is made aware that the content, while being accessible via the Services, may represent a risk for Users, third parties and/or the availability of the Service; or</li>
		                <li>(e) For any other reason that the Supplier deems appropriate.</li>
		            </ul>
		        </li>
		        <li>15.6 The removal, deletion, blocking or rectification of content shall not entitle the Customer and/or Users who have provided such content or who are responsible for it, to any claims for compensation, damages or reimbursement.</li>
		        <li>15.7 The Customer and Users agree to hold the Supplier harmless from and against any claim asserted and/or damage suffered due to content provided by either of them to or provided through the Services.</li>
		    </ul>
		    <p><strong>16. Termination</strong></p>
		    <ul>
		        <li>16.1 Without affecting any other right or remedy available to it, either party may terminate the Contract by giving written notice, with the Contract being deemed to be terminated on receipt of the said Notice by the non-terminating party.</li>
		        <li>16.2 Where the Contract is terminated, the provisions of clause 5.11 shall apply where the Renewal Term has already commenced when the Contract is terminated, and where the Renewal Term is for 12-months.  Where the Customer Selection is one-month, and where the Contract is terminated during that period, the Customer will be liable for the Subscription Fees for the remainder of that month.</li>
		        <li>16.3 Without affecting any other right or remedy available to it, the Supplier may, at its sole discretion, suspend the supply of Services under the Contract or any other contract between the Client and the Company if:
		            <ul>
		                <li>(a) the Customer fails to pay any amount due under the Contract on the due date for payment;</li>
		                <li>(b) The Customer or Users are in breach of any of the Conditions and in particular the obligations placed on it by clauses 3 or 7; or</li>
		                <li>(c) the Supplier determines it is reasonable to do so on commercial grounds.</li>
		            </ul>
		        </li>
		        <li>16.4 Users can terminate their account and cease using the Services at any time by taking the following steps:
		            <ul>
		                <li>(a) By following the account termination procedure on the Software</li>
		                <li>(b) By making a written request to the terminate to the Supplier.</li>
		            </ul>
		        </li>
		    </ul>
		    <p><strong>17. Consequences of termination</strong></p>
		    <ul>
		        <li>17.1 On termination or expiry of the Contract the Customer shall immediately pay to the Supplier all of the Supplier's outstanding unpaid invoices (and where applicable interest) and, in respect of Services supplied but for which no invoice has been submitted, the Supplier shall submit an invoice, which shall be payable by the Customer immediately on receipt.</li>
		        <li>17.2 Termination or expiry of the Contract shall not affect any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination or expiry, including the right to claim damages in respect of any breach of the Contract which existed at or before the date of termination or expiry.</li>
		        <li>17.3 Any provision of the Contract that expressly or by implication is intended to come into or continue in force on or after termination or expiry of the Contract shall remain in full force and effect.</li>
		    </ul>
		    <p><strong>18. General</strong></p>
		    <ul>
		        <li><strong>18.1 Force majeure</strong>. Neither party shall be in breach of the Contract nor liable for delay in performing, or failure to perform, any of its obligations under the Contract if such delay or failure result from events, circumstances or causes beyond its reasonable control.</li>
		        <li><strong>18.2 Assignment and other dealings.</strong>
		            <ul>
		                <li>(a) The Supplier may at any time assign, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with any or all of its rights and obligations under the Contract.</li>
		                <li>(b) The Customer shall not assign, transfer, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with any of its rights and obligations under the Contract.</li>
		            </ul>
		        </li>
		        <li><strong>18.3 Confidentiality.</strong>
		            <ul>
		                <li>(a) Each party undertakes that it shall not at any time disclose to any person any confidential information concerning the business, affairs, customers, clients or suppliers of the other party, except as permitted by clause 18.3(b).</li>
		                <li>(b) Each party may disclose the other party's confidential information:
		                    <ul>
		                        <li>(i) to its employees, officers, representatives, contractors, subcontractors or advisers who need to know such information for the purposes of carrying out the party's obligations under the Contract. Each party shall ensure that its employees, officers, representatives, contractors, subcontractors or advisers to whom it discloses the other party's confidential information comply with this clause 18.3; and</li>
		                        <li>(ii) as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority.</li>
		                    </ul>
		                </li>
		                <li>(c) Neither party shall use the other party's confidential information for any purpose other than to perform its obligations under the Contract.</li>
		            </ul>
		        </li>
		        <li><strong>18.4 Entire agreement.</strong>
		            <ul>
		                <li>(a) The Contract constitutes the entire agreement between the parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.</li>
		                <li>(b) Each party acknowledges that in entering into the Contract it does not rely on and shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in the Contract. Each party agrees that it shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in the Contract.</li>
		                <li>(c) Nothing in this clause shall limit or exclude any liability for fraud.</li>
		            </ul>
		        </li>
		        <li><strong>18.5 Variation.</strong> Except as set out in these Conditions, no variation of the Contract shall be effective unless it is agreed in writing by the Supplier.</li>
		        <li><strong>18.6 Waiver.</strong> A waiver of any right or remedy under the Contract or by law is only effective if given in writing and shall not be deemed a waiver of any subsequent right or remedy. A failure or delay by a party to exercise any right or remedy provided under the Contract or by law shall not constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict any further exercise of that or any other right or remedy. No single or partial exercise of any right or remedy provided under the Contract or by law shall prevent or restrict the further exercise of that or any other right or remedy.</li>
		        <li><strong>18.7 Severance.</strong> If any provision or part-provision of the Contract is or becomes invalid, illegal or unenforceable, it shall be deemed deleted, but that shall not affect the validity and enforceability of the rest of this agreement. If any provision or part-provision of this Contract deleted under this clause 18.7 the parties shall negotiate in good faith to agree a replacement provision that, to the greatest extent possible, achieves the intended commercial result of the original provision.</li>
		        <li><strong>18.8 Notices.</strong>
		            <ul type="a">
		                <li>Any notice or other communication given to a party under or in connection with the Contract shall be in writing and shall be delivered by hand or by pre-paid first-class post or other next working day delivery service at its registered office (if a company) or its principal place of business (in any other case); sent by email to the address specified in the Subscription.</li>
		                <li>
		                    Any notice or communication shall be deemed to have been received:
		                    <ul type="i">
		                        <li>If delivered by hand, at the time the notice is left at the proper address;</li>
		                        <li>If sent by pre-paid first-class post or other next working day delivery service, at 9.00 am on the Business Day after posting; or</li>
		                        <li>If sent by email at the time of transmission, or, if this time falls outside business hours in the place of receipt, when business hours resume. In this clause 18.8(b)(iii), business hours means 9.00am to 5.00pm Monday to Friday on a day that is not a public holiday in the place of receipt.</li>
		                    </ul>
		                </li>
		                <li>This clause 18.8 does not apply to the service of any proceedings or other documents in any legal action or, where applicable, any other method of dispute resolution.</li>
		            </ul>
		        </li>
		        <li><strong>18.9 Third party rights.</strong> Unless it expressly states otherwise, the Contract does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Contract.</li>
		        <li><strong>18.10 Governing law.</strong> The Contract, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance with the law of Northern Ireland.</li>
		        <li><strong>18.11 Jurisdiction.</strong> Each party irrevocably agrees that the courts of Northern Ireland shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with the Contract or its subject matter or formation.</li>
		    </ul>
		</div>
	</div>
	<Footer/>
  </Layout>
)

export default Terms;
